<template>
	<ZeroPayment :program_id="1" />
</template>

<script>
import ZeroPayment from "@/views/commons/components/zero-payment/ZeroPayment.vue"

export default {
  components: {
    ZeroPayment
  }
}
</script>
